/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('RESOURCE/fonts/HelveticaNeueMedium.eot');
	src: url('RESOURCE/fonts/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/HelveticaNeueMedium.woff2') format('woff2'),
		url('RESOURCE/fonts/HelveticaNeueMedium.woff') format('woff'),
		url('RESOURCE/fonts/HelveticaNeueMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('RESOURCE/fonts/HelveticaNeueBold.eot');
	src: url('RESOURCE/fonts/HelveticaNeueBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/HelveticaNeueBold.woff2') format('woff2'),
		url('RESOURCE/fonts/HelveticaNeueBold.woff') format('woff'),
		url('RESOURCE/fonts/HelveticaNeueBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('RESOURCE/fonts/HelveticaNeueLight.eot');
	src: url('RESOURCE/fonts/HelveticaNeueLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/HelveticaNeueLight.woff2') format('woff2'),
		url('RESOURCE/fonts/HelveticaNeueLight.woff') format('woff'),
		url('RESOURCE/fonts/HelveticaNeueLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'HelveticaNeue';
	src: url('RESOURCE/fonts/HelveticaNeue-Roman.eot');
	src: url('RESOURCE/fonts/HelveticaNeue-Roman.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/HelveticaNeue-Roman.woff2') format('woff2'),
		url('RESOURCE/fonts/HelveticaNeue-Roman.woff') format('woff'),
		url('RESOURCE/fonts/HelveticaNeue-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}