.regionen-v1 {
	margin-top: var(--page-margin-top);

	.region-bl {
		display: inline-block;
		width: 100%;

		li {
			width: 50%;
			overflow: hidden;
			float: left;
			height: 335px;
			display: inline-block;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 1;
			border-radius: 5px;
			margin-bottom: 30px;
			padding: 0 15px;

			@media (max-width:1239px) {
				height: 307px;
			}

			@media (max-width:1199px) {
				height: 256px;
			}

			@media (max-width:991px) {
				height: 189px;
			}

			@media (max-width:767px) {
				height: 35vh;
				width: 100%;
			}





			.region-list {
				width: 100%;
				height: 100%;
				overflow: hidden;
				position: relative;
				border-radius: var(--border-radius);

				&:after {
					content: "";
					background-color: rgba(0, 0, 0, .2);
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					z-index: 1;
				}

				a {
					display: inline-block;
					height: 100%;
					width: 100%;
					z-index: 2;
					position: relative;

					span {
						text-align: center;
						padding: 0 10px;
						font-size: 20px;
						font-weight: 400;
						color: #fff;
						position: relative;
						z-index: 2;
						text-transform: uppercase;
						text-decoration: none;
						position: absolute;
						transition: transform .35s, color .35s, -webkit-transform .35s;
						transform: translate3d(0, -50%, 0);
						top: 50%;
						left: 0;
						right: 0;
					}
				}
			}




			&:nth-child(1) {
				.region-list {
					&:before {
						content: "";
						background: url(RESOURCE/img/region_Braunlage.webp) no-repeat 0 0;
						height: 100%;
						position: absolute;
						z-index: -1;
						width: 100%;
						transition: opacity 1s, transform 1s, -webkit-transform 1s;
						background-size: cover;
					}
				}
			}

			&:nth-child(2) {
				.region-list {
					&:before {
						content: "";
						background: url(RESOURCE/img/Hahnenklee.jpg) no-repeat 0 0;
						height: 100%;
						position: absolute;
						z-index: -1;
						width: 100%;
						transition: opacity 1s, transform 1s, -webkit-transform 1s;
						background-size: cover;
					}
				}
			}

			&:nth-child(3) {
				.region-list {
					&:before {
						content: "";
						background: url(RESOURCE/img/region_Schulenberg.webp) no-repeat 0 0;
						height: 100%;
						position: absolute;
						z-index: -1;
						width: 100%;
						transition: opacity 1s, transform 1s, -webkit-transform 1s;
						background-size: cover;
					}
				}
			}

			&:nth-child(4) {
				.region-list {
					&:before {
						content: "";
						background: url(RESOURCE/img/region_Wildemann.webp) no-repeat 0 0;
						height: 100%;
						position: absolute;
						z-index: -1;
						width: 100%;
						transition: opacity 1s, transform 1s, -webkit-transform 1s;
						background-size: cover;
					}
				}
			}

			&:hover {
				a {
					span {
						-webkit-transform: translate3d(0, -40px, 0);
						transform: translate3d(0, -40px, 0);
					}
				}

				.region-list {
					&:before {
						content: "";
						-webkit-transform: scale3d(1.1, 1.1, 1);
						transform: scale3d(1.1, 1.1, 1);
						opacity: .7;
					}
				}
			}
		}
	}
}