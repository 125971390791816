.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {

		&.banner11349 {
			background-image: url(RESOURCE/img/region_Braunlage.webp);
		}

		&.banner2042 {
			background-image: url(RESOURCE/img/Goslar.jpg);
		}

		&.banner2043 {
			background-image: url(RESOURCE/img/Hahnenklee.jpg);
		}

		&.banner11733 {
			background-image: url(RESOURCE/img/region_Schulenberg.webp);
		}

		&.banner11734 {
			background-image: url(RESOURCE/img/region_Wildemann.webp);
		}

		&.regionen {
			background-image: url(RESOURCE/img/region_hero.jpeg);
		}

		&.kontakt {
			background-image: url(RESOURCE/img/contact_banner.jpg);
		}

	}

	&.harz-erleben-v1 {

		.harz-erleben-wraper {

			a {
				text-decoration: none;
				margin-bottom: 30px;
			}

			.img1 {
				grid-area: img1;
				background-image: url(RESOURCE/img/kamin.webp);
			}


			.img2 {
				grid-area: img2;
				background-image: url(RESOURCE/img/sauna.webp);
			}

			.img3 {
				grid-area: img3;
				background-image: url(RESOURCE/img/hund.webp);
			}

			.img4 {
				grid-area: img4;
				background-image: url(RESOURCE/img/familie.webp);
			}



			[class^='theme-card'] {
				color: white;
				text-decoration: none;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top;
				padding: 10px;

				@media (max-width: 767px) {
					height: 35vh;
				}


				&:hover {
					text-decoration: none;
				}

				h2 {
					font-size: var(--theme-card-title);
					color: var(--theme-card-text-color);
				}

				p {
					font-size: var(--theme-card-description);
					color: var(--theme-card-text-color);
				}

				a {
					text-decoration: none;
				}
			}

			.theme-card-1 {
				min-width: 100px;
				min-height: 100px;
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.55);
				background-blend-mode: multiply;
				transition: ease-out 0.5s;
			}

			.theme-card-2 {
				min-width: 100px;
				min-height: 100px;
				position: relative;
				width: 100%;
				height: 100%;
				color: white;
				text-decoration: none;
				font-size: 1.2em;
				transition: ease-out 0.5s;
				display: flex;
				align-items: center;
				justify-content: center;
			}



			.theme-card-3 {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				padding: 0;
				border-radius: var(--border-radius);
				position: relative;
				background-image: none;
				background-color: rgba(0, 0, 0, 0.2) !important;
				overflow: hidden;

				img {
					height: 100%;
					transition: opacity 1s, transform 1s;
					object-fit: cover;
				}

				&:before {
					content: "";
					background-color: rgba(0, 0, 0, 0.2);
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					z-index: 1;
				}

				&:hover {
					img {
						transform: scale3d(1.1, 1.1, 1);
						opacity: 0.7;
					}

					&:before {
						background-color: rgba(0, 0, 0, 0.4);
					}
				}
			}

			.theme-card-3--description-container {
				background-color: var(--overlay-color);
				padding: 10px;
				/*transition: 1s;*/
				transition: transform 0.35s, color 0.35s;
				border-bottom-left-radius: var(--border-radius);
				border-bottom-right-radius: var(--border-radius);
				height: 50px;
				top: 50%;
				transform: translate3d(0, -50%, 0);
				position: absolute;
				background: transparent;
				left: 0;
				right: 0;
				z-index: 2;

			}



			@media (min-width: 767px) {
				.theme-card-1--description-container .description {
					opacity: 0;
					transition: ease-out 0.5s;

				}

				.theme-card-1:hover .description {
					opacity: 1;
					transition: ease-in 0.5s;

				}

				.theme-card-1:hover {
					background-color: rgba(0, 0, 0, 0.55);
					background-blend-mode: multiply;
					transition: ease-in 0.5s;
				}

				.theme-card-1:not(:hover) {
					background-color: initial;
					background-blend-mode: multiply;
					transition: ease-out 0.5s;
				}

				.theme-card-2:hover {
					background-color: var(--overlay-color);
					background-blend-mode: multiply;
					transition: ease-in 0.5s;
				}

				.theme-card-2:not(:hover) {
					background-color: initial;
					background-blend-mode: multiply;
					transition: ease-out 0.5s;
				}

				.theme-card-3--description-container {
					height: 50px;
				}

				.theme-card-3--description-container .title {
					text-align: center;
					padding: 0px 10px;
					font-size: 20px;
					font-weight: 400;
				}

				.theme-card-3--description-container .description {
					padding: 30px;
				}

				.theme-card-3:hover .theme-card-3--description-container {
					height: 50px;
					transform: translate3d(0, -40px, 0);
				}

				.theme-card-3 .theme-card-3--description-container>p {
					opacity: 0;
					display: none;
				}

				.theme-card-3:hover .theme-card-3--description-container>p {
					opacity: 1;
					transition: 1s ease-in;
					display: block;
				}
			}

			@media (max-width: 766px) {

				.theme-card-1--description-container,
				.theme-card-2--description-container,
				.theme-card-3--description-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;
					padding: 30px;

				}
			}
		}
	}

	&.veranstaltungen-v1 {
		iframe {
			height: 833px;
			width: 100%;
			margin-bottom: 30px;
		}
	}

	&.veranstaltungen-v1 {
		.event-panel-header {
			margin-bottom: 30px;
		}

		.event-panel-header,
		.event-panel {
			padding: 15px;
			background: var(--color-grey-light);

			h2 {
				margin-bottom: 10px;
				padding-bottom: 15px;
			}
		}

		.icon_list_wrapper {
			display: flex;

			.icon {
				margin-right: 15px;
				color: var(--color-primary);
			}
		}

		h3 {
			font-size: 1.3rem;
			color: var(--color-primary);
		}
	}

	.logo-para {
		float: left;
		margin-right: 15px;
	}
}

ul.list-li {


	li {
		margin-bottom: 10px;
		font-weight: 400;

		i {
			color: var(--color-primary);
			margin-right: 10px;
		}
	}
}